const card = {
    identifier: 'card',
    title: 'Картой (Visa, MasterCard, МИР)',
};

// const yoomoney = {
//     identifier: 'ym',
//     title: 'YooMoney',
// };

// const qiwi = {
//     identifier: 'qiwi',
//     title: 'Qiwi',
// };

const crypto = {
    // "crypto" isn't a real payment method but a workaround to show user list of payment methods
    // and yet have ability to distinguish it from mobile
    identifier: 'crypto',
    title: 'Криптовалюты',
};

const sbp = {
    identifier: 'sbp',
    title: 'СБП (Система Быстрых Платежей)',
    extra: 'Только годовая подписка',
}

const mobile = {
    // "mobile" isn't a real payment method but a workaround to show user list of payment methods
    // and yet have ability to distinguish it from crypto
    identifier: 'mobile',
    title: 'Мобильный счет (+40% комиссия)',
    extra: 'Мегафон, Теле2',
}

const telegramStars = {
    identifier: 'stars',
    title: 'Telegram Stars',
    extra: 'Можно купить через банковские карты РФ',
}

// These methods has special cases 
export const SPECIAL_METHODS = []; 

export const paymentMethods = [card, telegramStars, mobile, crypto];
